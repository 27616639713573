import React from 'react';
import { graphql } from 'gatsby';
import ArticleTemplate, { ArticleType } from '../components/ArticleTemplate';
import Cookie from '../components/shared/Cookie';

const News = ({ data }) => {
  const { wpNewsItem: newsPage, allWpNewsItem } = data;

  const siteMeta = data.wp.generalSettings;

  return (
    <ArticleTemplate
      article={newsPage}
      allWpNews={allWpNewsItem}
      articleType={ArticleType.news}
      siteMeta={siteMeta}
      seo={newsPage.seo}
    />
  );
};

export const pageQuery = graphql`
  query NewsById($id: String!) {
    wpNewsItem(id: { eq: $id }) {
      ...NewsFragment
    }
    ...AllWordpressWpNews
    ...WordpressSiteMetadataFragment
  }
`;

export default News;
